.services-list-banner {
  .services-list-wrap {
    justify-content: space-between;
    @include media-breakpoint-down(sm){
      justify-content: start;
    }
    @media (max-width: 390px) {
      justify-content: center;
    }
  }
  .link-button {
   opacity: 0;
    transition: opacity 0.3s;
    @include media-breakpoint-down(md){
      display: none;
    }
  }
}

.services-list-item {
  @include media-breakpoint-down(lg){
    margin-bottom: 40px;
    margin-right: 20px;
  }
  @include media-breakpoint-down(md){
    margin-right: 0;
    margin-bottom: 20px;
  }

}
.services-list-item:hover {
  svg * {
    stroke: $th-blue;
  }
  .swiper-bullet-text {
    color: $th-blue;
  }

  .link-button {
    opacity: 1;
    @include media-breakpoint-down(md){
      display: none;
    }

  }
}
.services-list-item:last-of-type {
  margin-top: -10px;
}