.text { //tinka ir suzinokite daugiau lygtukams, tekstams, mygtuku tekstams, nav
    font-size: 16px;
    line-height: 22px;
   }
   
   .text-lg {
    font-size: 20px;
    line-height: 27px;
   }
   
   .text-sm-wider {
    font-size: 12px;
    line-height: 24px;
   }
   
   .text-xs {
    font-size: 10px;
    line-height: 16px;
   }
   
   .text-sm {
    font-size: 12px;
    line-height: 22px;
   }
   
   .title { //tinka skaiciu subtitlams
    font-size: 28px;
    line-height: 40px;
     @include media-breakpoint-down(md){
       font-size: 22px;
       line-height: 30px;
     }
   }
   
   .title-lg {
    font-size: 40px;
    line-height: 55px;
     @include media-breakpoint-down(md){
       font-size: 30px;
       line-height: 41px;
     }
   }
   
   .title-xl {
    font-size: 48px;
    line-height: 66px;
     @include media-breakpoint-down(sm){
       font-size: 28px;
       line-height: 38px;
     }
   }
   
   .service-logo-text {
    font-size: 18px;
    line-height: 15px;
   }
   
   .footer-text {
    font-size: 16px;
    line-height: 24px;
   }

   .justified {
    text-align: justify;
   }

   .centered {
    text-align: center;
   }

.footer-text-sm {
  font-size: 14px;
  line-height: 28px;
  @include media-breakpoint-down(md){
    font-size: 12px;
    line-height: 24px;
  }
}
   
   .contact-text {
    font-size: 14px;
    line-height: 28px;
   }
   
   .form-label {
    font-size: 14px;
    line-height: 19px;
   }

   .swiper-bullet-text {
     font-size: 18px;
     line-height: 25px;
     color: $th-dark-blue;
     max-width: 224px;
     @include media-breakpoint-down(lg){
       font-size: 14px;
       line-height: 19px;
       max-width: 120px;
       text-align: center;
       @media (max-width: 389px){
         max-width: 80px;
         margin: 0 10px;
       }
     }
   }

   .white {
     color: $white;
   }
   .white-bg {
     background-color: $white;
   }

   .blue {
     color: $th-blue;
   }

   .blue-bg {
     background-color: $th-blue;
   }

   .dark-blue-bg {
     background-color: $th-dark-blue;
   }

   .light-blue-bg {
     background-color: $th-light-blue;
   }

   .indigo-bg {
     background-color: $th-indigo;
   }

   .sheer {
     color: $sheer;
   }

   .section-padding {
     padding-top: 80px;
     padding-bottom: 80px;
     @include media-breakpoint-down(md){
       padding-top: 40px;
       padding-bottom: 40px;
     }

     &.services-list-banner {
       @include media-breakpoint-down(md){
         padding-bottom: 0;
       }
     }
   }

   .section-padding-sm {
     padding-top: 50px;
     padding-bottom: 80px;
     @include media-breakpoint-down(md){
       padding-top: 30px;
       padding-bottom: 40px;
     }
     &.contacts {
       @include media-breakpoint-down(md){
         padding-bottom: 0;
       }
     }
   }

   .medium {
     font-weight: 600;
   }

   .opacity-60 {
     opacity: 0.6;
   }

.logo-text {
  font-size: 25px;
  line-height: 34px;
  letter-spacing: -0.25px;
  text-transform: uppercase;
  margin-left: 5px;
}

.strong {
  font-weight: bold;
}