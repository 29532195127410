.contact-side-wrap {
  img {
    width: 183px;
    height: 183px;
  }
  .link-button {
    p {
      max-width: 80%;
    }
  }
}

