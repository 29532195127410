.it-solutions-banner {
  background-size: cover;
  background-repeat: no-repeat;
  height: 410px;
  width: 100%;
  padding: 100px 0;
  margin-top: 100px;
  @include media-breakpoint-down(sm){
    padding: 40px 0;
    height: unset;
    margin-top: 20px;
  }
  .banner-features:first-of-type {
    margin-right: 89px;
  }
}