.it-solutions-section-image {
  margin-top: 0;
  // background-color: $th-grey!important;
  padding:0;
  @include media-breakpoint-down(sm) {
    margin-top: 20px;
    padding: 20px 0;
  }
  img{
    display: block;
    margin: auto;
    max-width: 720px;
    max-height: 540px;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}

.full-buttons-wrap {
  a:first-of-type {
    margin-bottom: 10px;
  }
}