body {


  &.fixed {
    position: fixed;
  }
}

html {
  font-size: 20px;
  scroll-behavior: smooth;

}

* {
  font-family: "Avenir";
  color: $th-indigo;
  font-weight: 400;
}

img {
  //height: auto;
}

a, button {
  transition: .3s;
  cursor: pointer;

}

a {
  color: inherit;

  @include hover-focus-active {
    color: inherit;
    text-decoration: none;
  }
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: initial;
}

.transition {
  transition: .3s;
}

li {
  margin-bottom: 20px;
  @extend .text;
  &:last-of-type {
    margin-bottom: 0;
  }
}

ul {
  list-style: disc;
  margin-left: 20px;
}


::placeholder {
  font-size: 16px;
  line-height: 22px;
  opacity: 0.6;
}

*:focus-within, *:focus-visible {
  outline: none!important;

}


