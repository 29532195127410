// variables
@import "variables";

// Bootstrap styles
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/mixins";

@import "reset";
@import "fonts";
@import "global";

// Mixins
@import "mixins/mixins";

// Component specific styles
@import "components/all";

// Pages
@import "pages/all";

// Blocks
@import "blocks/all";

.a-hover:hover {
  text-decoration: underline;
}


