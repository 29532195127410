.partner-logos {
  //gap: 50px;
  //row-gap: 130px;
  @include media-breakpoint-down(md){
    column-gap: 90px;
    row-gap: 130px;
    img:nth-child(n+5){
      display: none;
    }
  }
  @media (max-width: 419px) {
    gap: 50px;
  }
}

.partners-section {
  button {
    display: block;
    margin: 50px auto 0 auto;
    @include media-breakpoint-down(sm){
      margin: 50px 0 0 0;
      width: 100%;
    }
  }
  &.dark-blue-bg {
    img {

    }
  }
}