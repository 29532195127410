.service-section {
  position: relative;
  .service-section-info{
    position: relative;
    z-index: 6;
    @include media-breakpoint-down(md){
      order: 2;
    }
  }
  .service-section-img{
    position: relative;
    z-index: 5;
    @include media-breakpoint-down(md){
      order: 1;
    }
  }
  .link-button:nth-of-type(2) {
    margin-left: 73px;
    @media (max-width: 380px) {
      margin-left: 30px;
    }
  }
  &:last-of-type {
    padding-bottom: 0!important;
  }
&:first-of-type, &:nth-of-type(4) {
  .service-section-img {
    z-index: 3;
  }
}
}

.services-page {
  overflow: hidden;
  &:after {
    content: "";
    background: url("../../assets/img/half-line.svg");
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: bottom;
    bottom: 0;
    z-index: 2;
    left: 0;
    right: 0;
    background-size: contain;
    @include media-breakpoint-down(sm){
      width: 210%;
      bottom: 245px;
      left: -300px;
      z-index: 0;
    }
  }
  &:before {
    content: "";
    background: url("../../assets/img/dark-background-line.svg");
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    bottom: 70px;
    z-index: 2;
    left: 0;
    right: -20px;
    background-size: contain;
    @include media-breakpoint-down(sm){
      width: 210%;
      bottom: 310px;
      left: -300px;
    }
  }
}

.services-page-fluid-wrap {
  .pseudo::after {
    content: "";
    background: url("../../assets/img/dashed-blue.svg");
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    z-index: 2;
    left: 0;
    right: 0;
    top: 450px;
    background-size: contain;
    @include media-breakpoint-down(sm){
      width: 310%;
      background-position: top;
      top: 230px;
      left: -50px;
      z-index: -1;
    }
    @include media-breakpoint-down(md){
      z-index: 0;
    }
    @include media-breakpoint-down(lg){
      z-index: 0;
    }
  }
}



