.social-icons {
  margin: 30px 0 50px 0;
  .social-icon-wrap {
    width: 50px;
    height: 50px;
    border: 1px solid $extra-sheer;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: all 0.3s;

    svg {

    }
    &:hover {
      border: 1px solid $sheer;
    }
  }
}

.contacts {
  .social-icons {
    margin: 0 0 40px 0;
    @include media-breakpoint-down(sm){
      margin-bottom: 30px;
    }
    .social-icon-wrap {
      border: 1px solid $extra-sheer-blue;
      svg * {
        fill: $th-blue;
      }
      &:hover {
        border: 1px solid $sheer-blue;
      }
    }
  }
}