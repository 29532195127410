nav.navbar {
  position: sticky;
  z-index: 9;
  top: 0;
  background-color: $white;
  height: 80px;
  padding: 0;

  a {
    transition: color 0.3s;
    &:hover, .web-nav-dropdown-toggle:hover {
      color: $th-blue;
    }
  }
  .web-nav-dropdown-toggle:hover {
    color: $th-blue;
  }
  .navigation-links-wrap {
    .nav-link {
      padding: 0;
      @include media-breakpoint-down(lg){
        padding: 17px 10px;
      }
    }
    @include media-breakpoint-down(lg){
      margin-top: 80px;
    }
  }
  .logo {
    z-index: 12;
  }
}

.navbar-toggler {

  z-index: 12;
  border: none;
  outline: none !important;
  padding: 0;
  @include media-breakpoint-down(sm){
    margin-right: 20px;
  }
}

button label {
  position: relative;
  height: 18px;
  width: 29px;
  z-index: 5;

  span {
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    margin-top: -1px;
    left: 0;
    display: block;
    transition: .5s;
    background-color: $th-dark-blue;

  }

  span:first-child {
    top: 1px;
  }

  span:last-child {
    top: 18px;
  }

}

button:not(.collapsed) label {
  span {
    opacity: 0;
    top: 50%;
  }

  span:first-child {
    opacity: 1;
    transform: rotate(405deg);
  }

  span:last-child {
    opacity: 1;
    transform: rotate(-405deg);
  }
}

@include media-breakpoint-down(lg) {
  .navbar-collapse {
    position: fixed;
    width: 50vw;
    top: 0;
    left: auto;
    overflow-y: auto;
    height: 100vh;
    right: -100%;
    display: block !important;
    transition: .3s;
    background-color: $white;
    z-index: 11;

    @include media-breakpoint-down(sm) {
      width: 100vw;
    }

    &.show {
      right: 0;
    }

    .not-a-button {
      padding: 17px 0;
      border-top: 1px solid rgba(31,49,80,0.1);
    }

    .nav-link {
      padding: 17px 0;
      border-top: 1px solid rgba(31,49,80,0.1);
    }

  }
}


.full-filter {
  //display: none;
  opacity: 0;
  transition: opacity 0.3s;
  &.visible {
    //display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: $th-dark-blue;
    opacity: .9;
  }
}