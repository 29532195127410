.team-card {
  img {
    max-width: 204px;
    max-height: 238px;
  }
}

.team-card-row {
  width: 100%;
  height: 190px;
  gap: 15px;
  background-color: #F2F7FA;
  justify-content: space-around;
  align-items: flex-end;
  //@include media-breakpoint-down(lg){
  //  margin-bottom: 50px;
  //
  //}
  //@media (max-width: 411px) {
  //  flex-wrap: wrap;
  //  justify-content: center;
  //  height: 952px;
  //}
  @media screen and (max-width: 411px) {
    flex-wrap: wrap;
    justify-content: center;
    height: 950px;
    //margin-bottom: 50px;
  }

  @media screen and (min-width: 411.1px) and (max-width: 513px) {
    flex-wrap: wrap;
    justify-content: center;
    height: 1220px;
    margin-bottom: 50px;
  }

  @media screen and (min-width: 513.1px) and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    height: 620px;
    margin-bottom: 50px;
  }
  @media screen and (min-width: 768.1px) {
    //flex-wrap: wrap;
    //justify-content: center;
    //height: 620px;
    margin-top: 1rem;
    margin-bottom: 100px;
  }
}

.team-profile {
  
  width: 204px;
  height: 238px;
  img {
    max-width: 204px;
    max-height: 238px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-down(lg){
      
    }
    @media (max-width: 411px) {
      max-width: 102px;
      max-height: 119px;
    }
  }
  
  
  //background-image: url("../img/NedasSukys.png");
  //background-color: black;
}